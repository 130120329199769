import { ContentType, HttpClient, RequestParams } from '../http-client';

export class VesselManagement<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  createCompanyVessel = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  editCompanyVessel = ({ id, ...data }: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyVessel = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyVesselById = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyAvailableVessel = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/assign`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyVesselGroup = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/group`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyVesselGroupById = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/group/${data.query}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  createCompanyVesselGroup = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/group`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  assignVesselToGroup = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/group/assign`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  removeVesselFromGroup = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/group/remove/${id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  createCompanyVesselRoute = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/route`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  editCompanyVesselRoute = (
    id: string,
    data: any,
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/route/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyVesselRoute = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/route/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyVesselDataById = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/data/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyVesselAvailableCrew = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/available/crew`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  assignCrewToVessel = (
    occupancyId: string,
    data: any,
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/crew-schedule/${occupancyId}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  assignCrewToSameRank = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/vessel/crew`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
