import { ContentType, HttpClient, RequestParams } from '../http-client';

export class ForgotPassword<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  forgotPasswordReset = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/forgot-password/reset`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  submitResetPassword = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/forgot-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  changeCompanyUserPassword = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/change-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
