const config = {
  tagline: 'Start posting jobs with ease and confidence now.',
  imagePlaceholder: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
  cookiesConfig: {
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    path: '/',
  },
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  apiSecret: process.env.NEXT_PUBLIC_API_SECRET,
};

export default config;
