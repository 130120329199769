import { ContentType, HttpClient, RequestParams } from '../http-client';

export class CompanyCrew<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * Create company account function
   * @param data { "full_name": string, "email": string, "phone_number": string}
   * @param params null
   * @returns customer account object
   */
  inviteCompanyCrew = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyCrew = (
    query: {
      page: number;
      full_name?: string;
      rank?: string;
      vessel_type?: string;
      crew_group?: string;
      status?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/crew`,
      method: 'GET',
      secure: true,
      ...(query && { query }),
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyCrewById = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  deleteCompanyCrewById = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew/${id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  downloadCompanyCrewById = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew/download/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
