import { ContentType, HttpClient, RequestParams } from '../http-client';

export class User<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  login = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  checkPhoneAndEmailAvailability = (
    query: { email: string; phone_number: string },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/crew/availability`,
      method: 'GET',
      ...(query && { query }),
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
