import { ContentType, HttpClient, RequestParams } from '../http-client';

export class CvLink<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  cvLinkLogin = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/cv-link/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCvLinkProfile = (data: { id: string }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/cv-link/${data.id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  cvLinkDownloadZipCoc = (data: { id: string }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/cv-link/zip-coc/${data.id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  cvLinkDownloadZipCop = (data: { id: string }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/cv-link/zip-cop/${data.id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  cvLinkDownloadZipTravelDocs = (
    data: { id: string },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/cv-link/zip-travel-docs/${data.id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  cvLinkRequestNewAccess = (data: { id: string }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/cv-link/request/${data.id}`,
      method: 'PATCH',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
