import { ContentType, HttpClient, RequestParams } from '../http-client';

export class Autofill<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  autofillDpCertificates = (
    data: { rank: string },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/autofill/dp-certificates/${data.rank}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  autofillVesselType = (data: { query?: string }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/vessel-type`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      query: {
        query: data.query,
      },
      ...params,
    });

  autofillJobRank = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/job/rank`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  autofillCoc = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/job/coc`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  autofillCop = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/job/cop`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  autofillCoe = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/job/coe`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  autofillVesselFlag = (data: { query?: string; }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/job/vessel-flag`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      query: {
        query: data.query,
      },
      ...params,
    });

  autofillCountry = (data: { query?: string }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/country`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      query: {
        query: data.query,
      },
      ...params,
    });

  autofillRank = (data: { query?: string }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/rank`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      query: {
        query: data.query,
      },
      ...params,
    });

  autofillCountryPhone = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/country-phone`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
