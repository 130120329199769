import { ContentType, HttpClient, RequestParams } from '../http-client';

export class Developer<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getApiSetting = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/webhook`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  patchApiSetting = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/webhook`,
      method: 'PATCH',
      secure: true,
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getApiLogs = (
    query: {
      page: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/api-logs`,
      method: 'GET',
      ...(query && { query }),
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  postApiLog = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/api-logs`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
