import { ContentType, HttpClient, RequestParams } from '../http-client';

export class CompanyHiringStage<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  createCompanyHiringStage = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/hiring-stages`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyHiringStages = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/hiring-stages`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  deleteCompanyHiringStage = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/hiring-stages/${id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  editCompanyHiringStage = (
    id: string,
    data: any,
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/hiring-stages/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  batchUpdateCompanyHiringStage = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/hiring-stages/many`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
