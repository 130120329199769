import { ContentType, HttpClient, RequestParams } from '../http-client';

export class CompanyCrewGroup<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  postCompanyCrewGroup = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyCrewGroup = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyCrewGroupById = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getAvailableCrewGroup = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group/crew/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getAvailableVesselGroup = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group/vessel/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  assignCrewGroup = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group/crew/assign`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  assignCrewGroupVessel = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group/vessel/assign`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  removeCrewFromCrewGroup = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group/crew/${id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  removeVesselGroupFromCrewGroup = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/crew-group/vessel-group/${id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
