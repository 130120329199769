import { parseCookies } from 'nookies';
import { cookies } from 'utils/constants';
import * as Sentry from '@sentry/nextjs';
import { AxiosRequestConfig } from 'axios';
import { HttpClient } from './../http-client';
import Config from 'utils/config';
import crypto from 'crypto';
import { CompanyDocument } from './CompanyDocuments';
import { CvLink } from './CvLink';
import { CompanyAccount } from './CompanyAccount';
import { CompanyHiringStage } from './CompanyHiringStage';
import { Jobs } from './Jobs';
import { ForgotPassword } from './ForgotPassword';
import { Autofill } from './Autofill';
import { CompanySetting } from './CompanySetting';
import { CompanyCrew } from './CompanyCrew';
import { VesselManagement } from './VesselManagement';
import { CompanyCrewGroup } from './CompanyCrewGroup';
import { Cbt } from './Cbt';
import { Company } from './Company';
import { Referral } from './Referral';
import { User } from './User';
import { Developer } from './Developer';

export const Axios = new HttpClient();

export const apiConfig = () => {
  const API_KEY = Config.apiKey;
  const API_SECRET = Config.apiSecret;
  const buffered = Buffer.from(API_SECRET, 'utf-8');
  const hashedSecret = crypto
    .createHash('sha256')
    .update(buffered)
    .digest('hex');

  return {
    key: API_KEY,
    secret: hashedSecret,
  };
};

Axios.instance.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_API_V2;

Axios.instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const API_CONFIG = apiConfig();
  config.headers.Accept = 'application/json';
  config.headers['X-API-Secret'] = API_CONFIG.key;
  config.headers['X-API-Key'] = API_CONFIG.key;
  if (config.withCredentials) {
    const token = parseCookies(config.context || {})[cookies.token];
    config.headers.Authorization = `Bearer ${API_CONFIG.key}:${API_CONFIG.secret}:${token}`;
  } else {
    config.headers.Authorization = `Bearer ${API_CONFIG.key}:${API_CONFIG.secret}`;
  }

  return config;
});

Axios.instance.interceptors.response.use(
  (res) => {
    if (res?.data && !res.data.isSuccess) {
      Sentry.captureMessage('User request fail');
    }
    return res;
  },
  (err) => {
    Sentry.captureException(err);
    return Promise.reject(err);
  }
);

const APIv2 = {
  ...new CompanyAccount(Axios),
  ...new CompanyHiringStage(Axios),
  ...new CompanyDocument(Axios),
  ...new CvLink(Axios),
  ...new Jobs(Axios),
  ...new ForgotPassword(Axios),
  ...new Autofill(Axios),
  ...new CompanySetting(Axios),
  ...new CompanyCrew(Axios),
  ...new VesselManagement(Axios),
  ...new CompanyCrewGroup(Axios),
  ...new Cbt(Axios),
  ...new Company(Axios),
  ...new Referral(Axios),
  ...new User(Axios),
  ...new Developer(Axios),
};

export default APIv2;
