import { ContentType, HttpClient, RequestParams } from '../http-client';

export class Referral<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getCrewByReferralCode = (
    query?: { code?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/crew/referral/crew`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });

  crewRegisterReferral = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/crew/register/email`,
      method: 'POST',
      body: data,
      secure: true,
      format: 'json',
      ...params,
    });

  crewOtpSubmit = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/crew/otp/submit`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
