import { ContentType, HttpClient, RequestParams } from '../http-client';

export class CompanyDocument<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  companyDocumentsGetAllDocuments = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company-documents`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });

  companyDocumentsUploadNib = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company-documents/upload/nib`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });

  companyDocumentsUploadSiupal = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company-documents/upload/siupal`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });

  companyDocumentsUploadNpwp = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company-documents/upload/npwp`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });

  companyDocumentsUploadLogo = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/logo`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
}
